import React from 'react'

class Buttons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { categories: props.categories };
  }

  toggleButton = (name) => {
    const categories = this.state.categories;

    const category = categories.find(c => c.name === name);
    category.active = !category.active;

    this.setState({categories: categories});
  };

  render() {
    return (

      <React.Fragment>
        <div className="buttons">
          {this.state.categories.map(category => <a className={`button ${category.active ? 'is-primary' : ''}`} onClick={() => this.toggleButton(category.name)}>{category.name}</a>)}
        </div>
      </React.Fragment>

    )}
}

export default Buttons
