import React from "react"
import {I18n} from "../../lib/helpers";
import CartAddEvent from '../../events/cart-add-event';
import CartRemoveEvent from '../../events/cart-remove-event';

class CartButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productCount: props.productCount,
    };
  }

  componentDidMount() {
    CartAddEvent.receive().subscribe( () => {
      const productCount = this.state.productCount;
      this.setState({ productCount: productCount + 1 })
    })
    CartRemoveEvent.receive().subscribe( () => {
      const productCount = this.state.productCount;
      this.setState({ productCount: productCount - 1 })
    })
  }

  render () {
    return (
      <a href="/cart/positions" className="button is-primary">
        <span><i className="fa fa-shopping-cart mr-1"></i></span>
        <span>
            { I18n.shared.navigation.shopping_cart } { " " } <strong> ({ this.state.productCount })</strong>
          </span>
      </a>
    );
  }

}

export default CartButton
