import React from "react"
import {I18n} from "../../lib/helpers";
import {updatePosition} from "../services/position-service";
import ProductAvailabilityChecker from "../products/ProductAvailabilityChecker";
import ProductDetails from "../products/ProductDetails";
import CartRemoveEvent from "../../events/cart-remove-event";

class PositionRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      position: props.position,
      isValid: props.position.amount > 0,
      showDetails: !!props.showDetails,
    };
  }

  onAvailabilityChange = (availability) => {
    const position = this.state.position;
    position.available = availability.available;
    position.amount = availability.amount;

    updatePosition(position)
      .then(res => this.setState({ position: res.data.position, isLoading: false, isValid: true }))
      .catch(() => this.setState({ isLoading: false, isValid: false }));
  };

  onDelete = () => {
    CartRemoveEvent.dispatch()
    this.props.deletePosition(this.state.position);
  };

  onShowDetailsToggle = () => {
    const showDetails = this.state.showDetails;
    this.setState({showDetails: !showDetails})
  };

  render () {
    return (
      <div className="mb-1">
        <div className="box p-0.5">
          <div className="columns is-vcentered">
            <div className="column is-clipped">
              <nav className="level">
                <div className="level-left">
                  <div className="level-item">
                    <p>
                      <strong>{ I18n.shopping_cart.my_shopping_cart.article }: </strong>
                      <a onClick={this.onShowDetailsToggle}>{this.state.position.uid}</a>
                    </p>
                  </div>
                  <div className="level-item">
                    <p>
                      <strong>{ I18n.shopping_cart.my_shopping_cart.designation }: </strong>
                      {this.state.position.nameOne} {this.state.position.nameTwo} {this.state.position.nameThree} {this.state.position.nameFour}
                    </p>
                  </div>
                  <div className="level-item is-hidden-touch">
                    <p>
                      <strong>{ I18n.product.your_product_number }: </strong>
                      {this.state.position.customerProductId || "-"}
                    </p>
                  </div>
                </div>
                <div className="level-right"/>
              </nav>
            </div>
            <div className="column is-narrow">
              <nav className="level">
                <div className="level-left"/>
                <div className="level-right">
                  <div className="level-item">
                    <ProductAvailabilityChecker uid={this.props.position.uid} amount={this.props.position.amount} onAvailabilityChange={this.onAvailabilityChange}/>
                  </div>

                  <p className="level-item">{this.state.position.quantityUnit}</p>

                  <div className="level-item">
                        <span data-balloon-pos="up" aria-label={`${this.state.position.available ? I18n.product.available : I18n.product.not_available + ", " + I18n.product.on_stock + ": " + this.state.position.stock + ", " + (this.state.position.deliverableIn ? I18n.product.deliverable_in  + " " + this.state.position.deliverableIn + " " + I18n.product.weeks : I18n.product.deliverable_in_on_request)} `} className="is-white is-medium">
                          <span className={`icon ${this.state.position.available ? 'has-text-success': 'has-text-warning'} `}>
                            <i className="fas fa-circle"/>
                          </span>
                        </span>
                  </div>

                  { this.state.position.price &&
                  <React.Fragment>
                    <p className="level-item">x</p>
                    <p className="level-item">
                            <span>
                                {this.state.position.price.pricePerUnit} {this.state.position.price.blanket ? I18n.products.table.titles.blanket_price: ` / ${this.state.position.unit}`}
                              </span>
                    </p>
                    <p className="level-item">=</p>
                  </React.Fragment>
                  }

                  { !this.state.position.price &&
                    <p className="level-item"><span>{I18n.shopping_cart.my_shopping_cart.price_on_request} </span></p>
                  }

                  <div className="level-item">
                    <p>{this.state.position.priceTotal ? this.state.position.priceTotal : "" }</p>
                  </div>

                  <div className="level-item">
                    <button className="button is-danger is-small" onClick={this.onDelete}>
                        <span className="icon is-small">
                           <i className="fas fa-trash-alt"/>
                        </span>
                    </button>
                  </div>

                  <div className="level-item">
                    <button className="button is-small is-primary" onClick={this.onShowDetailsToggle}>
                      <span>{I18n.shopping_cart.my_shopping_cart.show_details}</span>
                      <span className="icon is-small">
                          { this.state.showDetails || <i className="fas fa-caret-down"/> }
                        { this.state.showDetails && <i className="fas fa-caret-up"/> }
                        </span>
                    </button>
                  </div>

                </div>
              </nav>
            </div>
          </div>

          { this.state.showDetails &&
          <React.Fragment>
            <hr/>
            <ProductDetails product={this.state.position} key={this.state.position.uid}/>
          </React.Fragment>
          }
        </div>

      </div>
    );
  }

}

export default PositionRow
