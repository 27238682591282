import {Subject} from "rxjs/index";

class CartRemoveEvent {

  subject = new Subject();

  dispatch = (event) => {
    return this.subject.next(event);
  };

  receive = () => {
    return this.subject.asObservable();
  }

}

// Singleton
export default (new CartRemoveEvent());
