import React from 'react'
import DropdownButton from "./DropdownButton";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { optionList: props.optionList };
  }

  render() {

    return (
      <React.Fragment>
        <DropdownButton optionList={this.state.optionList}></DropdownButton>
      </React.Fragment>

    )}

}

export default DropdownButton
