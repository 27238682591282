import React from 'react'

class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropdownActive: false, optionList: props.optionList };
  }

  toggleDropdown = () => {
    const dropdownStatus = this.state.dropdownActive;
    this.setState({dropdownActive: !dropdownStatus});
  };

  toggleDropdownItem = (name) => {
    const options = this.state.optionList;

    const actualOption = options.find(o => o.name === name);
    actualOption.active = !actualOption.active;

    this.setState({optionList: options});
    //


    // onClick (add) wird der Wert des Dropdowns an einen String angehängt oder in eine Liste gespeichert
    // onClick (remove) wird der Wert des Dropdown in einem String gesucht und rausgeschnitten, oder der Eintrag in der Liste wird gelöscht
    // Liste wird im Endeffekt schlauer sein, sodass man dann noch eine Funktion zusätzlich schreibt, die sich diese Liste nimmt und in die URL als Parameter übergibt
  };

  render() {

    return (
      <React.Fragment>
        <div className={`dropdown ${this.state.dropdownActive ? 'is-active' : ''}`} onClick={this.toggleDropdown}>
          <div className="dropdown-trigger">
            <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
              <span>Dropdown button</span>
              <span className="icon is-small">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {this.state.optionList.map(option => <a className={`dropdown-item ${option.active ? 'is-active' : ''}`} onClick={() => this.toggleDropdownItem(option.name)}>{option.name}</a>)}
            </div>
          </div>
        </div>
      </React.Fragment>

    )}

}

export default DropdownButton
