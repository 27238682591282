import axios from "axios/index";

export function checkAvailability(uid, amount) {
  return axios.get(`/products/${uid}/availability.json?amount=${amount}`)
}

export function fetchScaledPrices(uid) {
  return axios.get(`/products/${uid}/scaled-prices.json`)
}

export function fetchReferenceNumbers(uid) {
  return axios.get(`/products/${uid}/reference-numbers.json`)
}
