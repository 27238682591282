import React from 'react';
import {createQuickSearch} from "../services/search-service";
import {I18n} from "../../lib/helpers";

export default class SheetSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      products: [],
      productUid: null,
      pdfUrl: null,
      isLoading: false
    };
  }

  componentDidMount() {}

  onProductUidChange = (event) => {
    this.setState({ productUid: event.target.value })
  };

  onSearch = async (event) => {
    event.preventDefault();
    if(this.state.productUid === null) return;

    const searchParams= {
      productUid: this.state.productUid,
    };

    this.setState({ isLoading: true });

    const result = await createQuickSearch(searchParams);

    const products = result.data
      .products
      .filter(p => p.attachment !== null)
      .filter((thing, i, arr) => arr.findIndex(t => t.compound === thing.compound) === i)

    this.setState({ products: products, isLoading: false })

  };

  toggleModal = (product) => {
    if(this.state.products.length === 0) return;

    let isActive = this.state.isActive;
    isActive = !isActive;

    if(isActive) {
      document.querySelector(':root').classList.add("is-clipped")
    } else {
      document.querySelector(':root').classList.remove("is-clipped")
    }
    this.setState({isActive: isActive, pdfUrl: product.attachment});
  }

  render() {
    return(
      <React.Fragment>
        <nav className={"level"}>
          <div className={"level-left"}>
            <div className="level-item">
              <p className="subtitle is-5">
                { I18n.sheets.search_headline } <br></br>
                <span className={"is-size-7"}>{ I18n.sheets.search_subline }</span>
              </p>
              <br></br>
            </div>

          </div>
          <div className={"level-right"}>
            <div className={"level-item"}>
              <input onChange={this.onProductUidChange} type={"text"} className={"input is-expanded"} placeholder={I18n.sheets.search_input_placeholder}></input>
            </div>
            <div className={"level-item"}>
              <button className={`button is-primary ${this.state.isLoading ? "is-loading" : ""}`} onClick={this.onSearch}>{ I18n.sheets.search_button_text }</button>
            </div>
          </div>
        </nav>

        <div className={"columns  is-multiline"}>
          {
            this.state.products.map((product, index) => {
              return <div key={index} className={"column is-one-quarter"} onClick={() => this.toggleModal(product)}>
                <div className={"box has-text-centered is-clickable"}>

                  <p>
                    <span className="fa fa-file-alt mr-1"></span>
                    {product.compound}
                  </p>
                </div>
              </div>
            })
          }
        </div>

        <div className={`modal ${this.state.isActive ? "is-active" : ""}`}>
          <div className="modal-background" onClick={this.toggleModal}></div>

          <div className="modal-content pdf-viewer">
            <div className={"columns is-fullheight"}>
              <div className={"column is-three-fifths is-offset-one-fifth is-fullheight"}>
                <iframe height={"100%"} width={"100%"} src={this.state.pdfUrl}></iframe>
              </div>
            </div>
          </div>

          <button onClick={this.toggleModal} className="modal-close is-large" aria-label="close"></button>
        </div>
      </React.Fragment>
    );
  }

}
