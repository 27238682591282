import React from 'react'
import {I18n, currentUser} from '../../lib/helpers';
import {fetchReferenceNumbers} from "../services/product-service";

class ReferenceNumbersTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isLoading: true,
      referenceNumbers: [],
      unit: props.product.unit || 'ERROR'
    };
  }

  componentDidMount() {
    fetchReferenceNumbers(this.props.product.uid)
      .then(response => this.setState({ isActive: true, isLoading: false, referenceNumbers: response.data }))
      .catch(error => this.setState({ isActive: true, prices: [], isLoading: false }));
  }

  render() {
    return (
      <React.Fragment>
        <h2 className="subtitle">
          { I18n.reference_numbers.modal_title }
        </h2>

        <div className="content">
          { this.state.referenceNumbers.length === 0 &&
          <div className="notification">{ I18n.reference_numbers.no_reference_numbers_set }</div>
          }

          { this.state.referenceNumbers.length > 0 &&
          <table className="table is-striped is-fullwidth">
            <thead>
            <tr>
              <th>{ I18n.reference_numbers.table_headline_one }</th>
              <th>{ I18n.reference_numbers.table_headline_two }</th>
            </tr>
            </thead>
            <tbody>

            {this.state.referenceNumbers.map((item, index) =>
              <tr key={index}>
                <td><p>{item.manufacturer}</p></td>
                <td><p>{item.name}</p></td>
              </tr>
            )}
            </tbody>
          </table>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default ReferenceNumbersTable
