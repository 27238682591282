import React from "react"
import PositionRow from "./PositionRow";
import axios from 'axios/index';
import {I18n} from "../../lib/helpers";
import ProductRow from "../products/ProductRow";
import {addToCart} from "../services/position-service";
import CartAddEvent from "../../events/cart-add-event";

class PositionTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      positions: props.positions,
      recommendedProducts: props.recommendedProducts
    }
  }

  deletePosition = (position) => {
    axios.delete(`/cart/positions/${position.id}.json`)
      .then(res => {
        const positions = this.state.positions.filter(pos => pos.id !== position.id);
        this.setState({ positions });
      })
  };

  handleAddToCart = (product, amount) => {
    if(!amount) {
      amount = 1;
    }
    addToCart(product, amount)
      .then(() => {
        CartAddEvent.dispatch()
        location.reload();
      })
      .catch(console.error)
  };

  render () {
    return (
      <React.Fragment>
        { this.state.positions.map((position) => <PositionRow position={position} key={position.id} deletePosition={this.deletePosition}/>) }

        <a href={"/products"} className={"button is-outlined is-primary is-small"}>{ I18n.shopping_cart.back_to_search }</a>

        { this.state.recommendedProducts.length > 0 &&
          <div>
            <h2 className="subtitle is-4 mt-4">{ I18n.shopping_cart.my_shopping_cart.recommended_products_title }</h2>
            { this.state.recommendedProducts.map((product) => <ProductRow product={product} key={product.id} showDetails={true} handleAddToCart={this.handleAddToCart}/>) }
          </div>
        }

      </React.Fragment>
    );
  }

}

export default PositionTable
