import React from 'react';

export default class ProductCategoryChooser extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className="buttons">
        { this.props.categories.map(category => <a key={category.id} onClick={() => this.props.onCategorySelect(category)} className={`button is-small ${category.active ? 'is-primary' : ''}`}>{category.name}</a>) }
      </div>
    );
  }

}
