import React from "react"
import {I18n} from "../../lib/helpers";
import ProductAvailabilityChecker from "./ProductAvailabilityChecker";
import ProductDetails from "./ProductDetails";
import {fetchScaledPrices} from "../services/product-service";
import {InView} from "react-intersection-observer";

class ProductRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: props.product,
      isValid: props.amount > 0,
      isPreloaded: false,
      amount: props.amount,
      addedToCart: false,
      showDetails: !!props.showDetails,
      isAvailable: props.product.stock >= 1 // Initial check if amount is larger than one
    };
  }

  onShowDetailsToggle = () => {
    const showDetails = this.state.showDetails;
    this.setState({showDetails: !showDetails})
  };

  preloadScaledPrices = (inView) => {
    if (!inView) return;
    if (this.state.isPreloaded) return;
    if (!this.state.product.uid) return;

    this.setState({isPreloaded: true});

    fetchScaledPrices(this.state.product.uid)
      .then(response => {
        const product = this.state.product;
        const price = response.data.sort((current, next) => current.amount - next.amount)[0];
        if (!price || response.data.length > 1) return;

        product.price = price;
        this.setState({product: product});
      })
  }

  onAvailabilityChange = (availability) => {
    const product = this.state.product;
    product.price = availability.price;
    this.setState({product: product, amount: availability.amount, isAvailable: availability.available});
  };

  onAddToCart = () => {
    this.setState({addedToCart: true});
    this.props.handleAddToCart(this.state.product, this.state.amount);
    setTimeout(() => this.setState({addedToCart: false}), 500);
  };

  render() {
    return (
      <InView as="div" triggerOnce={true} initialInView={false} delay={250} threshold={1} onChange={(inView, entry) => this.preloadScaledPrices(inView)}>
        <div className="mb-1">
          <div className="box p-0.5">
            <div className="columns is-vcentered">
              <div className="column is-clipped">
                <nav className="level">
                  <div className="level-left">
                    <div className="level-item">

                      <p>
                        <strong>{I18n.shopping_cart.my_shopping_cart.article}: </strong>
                        <a onClick={this.onShowDetailsToggle}>{this.state.product.uid}</a>
                      </p>

                    </div>
                    <div className="level-item">
                      <p>
                        <strong>{I18n.shopping_cart.my_shopping_cart.designation}: </strong>
                        {this.state.product.nameOne} {this.state.product.nameTwo} {this.state.product.nameThree} {this.state.product.nameFour}
                      </p>
                    </div>
                    <div className="level-item is-hidden-touch">
                      <p>
                        <strong>{I18n.product.your_product_number}: </strong>
                        {this.state.product.customerProductId || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="level-right"/>
                </nav>
              </div>
              <div className="column is-narrow">
                <nav className="level">
                  <div className="level-left"/>
                  <div className="level-right">

                    <div className="level-item">
                      <ProductAvailabilityChecker uid={this.state.product.uid} amount={this.state.amount}
                                                  onAvailabilityChange={this.onAvailabilityChange}/>
                    </div>

                    <p className="level-item">{this.state.product.quantityUnit}</p>

                    <div className="level-item">
                    <span data-balloon-pos="up"
                          aria-label={`${this.state.isAvailable ? I18n.product.available : I18n.product.not_available + ", " + I18n.product.on_stock + ": " + this.state.product.stock + ", " + (this.state.product.deliverableIn ? I18n.product.deliverable_in + " " + this.state.product.deliverableIn + " " + I18n.product.weeks : I18n.product.deliverable_in_on_request)} `}
                          className="is-white is-medium">
                      <span className={`icon ${this.state.isAvailable ? 'has-text-success' : 'has-text-warning'} `}>
                        <i className="fas fa-circle"/>
                      </span>
                    </span>
                    </div>

                    {this.state.product.price &&
                    <React.Fragment>
                      <p className="level-item">
                      <span>
                        {this.state.product.price.pricePerUnit} {this.state.product.price.blanket ? I18n.products.table.titles.blanket_price : ` / ${this.state.product.unit}`}
                      </span>
                      </p>
                    </React.Fragment>
                    }

                    {!this.state.product.price &&
                    <p className="level-item"><span>{I18n.shopping_cart.my_shopping_cart.price_on_request} </span></p>
                    }

                    <div className="level-item">
                      <button className={`button is-small ${this.state.addedToCart ? "is-success" : ""}`}
                              onClick={this.onAddToCart}>
                        <span className="icon is-small">
                           <i className="fas fa-shopping-cart"/>
                        </span>
                      </button>
                    </div>

                    <div className="level-item">
                      <button className="button is-small is-primary" onClick={this.onShowDetailsToggle}>
                        <span>{I18n.shopping_cart.my_shopping_cart.show_details}</span>
                        <span className="icon is-small">
                          {this.state.showDetails || "Weniger anzeigen" && <i className="fas fa-caret-down"/>}
                          {this.state.showDetails && "Mehr anzeigen" && <i className="fas fa-caret-up"/>}
                        </span>
                      </button>
                    </div>

                  </div>
                </nav>
              </div>
            </div>

            {this.state.showDetails &&
            <React.Fragment>
              <hr/>
              <ProductDetails product={this.state.product} key={this.state.product.uid}/>
            </React.Fragment>
            }
          </div>

        </div>
      </InView>
    );
  }

}

export default ProductRow
