import axios from "axios/index";

export function createSearch(name, filters, page = 1, tolerance) {
  if (!filters) {
    return Promise.reject({message: "No filters given!"});
  }
  return axios.post(`/searches.json?page=${page}`, {name: name, filters: filters, tolerance: tolerance })
}

export function createQuickSearch(searchParams) {
  if (!searchParams) {
    return Promise.reject({message: "No search parameters given!"});
  }
  return axios.post(`/products/quicksearch.json`, searchParams)
}
