import React from 'react';
import {I18n} from "../../lib/helpers";

export default class ProductOptionInput extends React.Component {

  constructor(props) {
    super(props);
  }

  onNameChoose = (name) => {
    const filter = this.props.filter;
    const values = filter.input.values || [];
    if (values.includes(name)) { return; }
    values.push(name);
    filter.input.values = values;
    this.props.handleFilterInput(filter);
  };

  onNameRemove = (name) => {
    const filter = this.props.filter;
    filter.input.values = filter.input.values.filter(n => n !== name);
    this.props.handleFilterInput(filter);
  };

  render() {
    return (
      <div className="field">
        <label className="label is-small has-text-grey">{ this.props.filter.name }</label>
        <div className="control is-expanded">
          <div className="field is-grouped is-grouped-multiline">
            { this.props.filter.input.values && this.props.filter.input.values.map(name => <div key={name} className="control">
                <div className="tags has-addons">
                  <a className="tag is-primary has-text-white">{name}</a>
                  <a onClick={() => this.onNameRemove(name)} className="tag is-delete"></a>
                </div>
              </div>
            )}
          </div>
          <div className="dropdown is-hoverable">
            <div className="dropdown-trigger">
              <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <span>{ I18n.products.search.detailsearch.select_article_attributes } {this.props.required && I18n.products.search.detailsearch.required_field }</span>
                <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
              </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu" >
              <div className="dropdown-content" style={{maxHeight: '300px', overflowY: 'auto'}}>
                {this.props.filter.options.names.sort().map(name => <a className="dropdown-item" key={name} value={name} onClick={() => this.onNameChoose(name)}>{name}</a>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


}
